<template>
	<div id="main-wrapper" class="inner-pages auto-page">
		<div class="section banner-holder">
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Autochartist </h2>
				<p>Lets you invest your trading time judiciously</p>
			</div>
		</div>
		<div class="section forex-content">
			<div class="wrapper2">
				<div class="section">
					<div class="content-sec mt4-phone zulu-web">
						<div class="img-holder wow fadeInLeft">
							<img src="assets/images/auto-img1.webp" alt="AAAFx" title="AAAFx" width="665" height="346" class="fluid-img" />
						</div>
						<div class="content-holder wow fadeInRight">
							<h3>Access one of the most popular trading tools free at AAAFx</h3>
							<br>
							<p>Make informed decisions as Autochartist analyses all charts and patterns for you.
							<br><br>
							If you are a trader, you know the value of time like no one else. We present to you an excellent tool which can save all your time and energy and do multiple kinds of analysis for you. </p>
						</div>
					</div>
				</div>
				<div class="section">
					<div class="content-sec mt4-phone zulu-web">
						<div class="content-holder wow fadeInLeft">
							<h3>How does Autochartist work? </h3>
							<br>
							<p>Never miss an opportunity!
							<br><br>
							Autochartist works on three kinds of patterns:</p>
							<ul class="listing-check listing-chart">
								<li>Chart Patterns</li>
								<li>Fibonacci Patterns</li>
								<li>Key Levels</li>
							</ul> <br>
							<p>It monitors the market and keeps sending alerts for trading opportunities. Let the technology work for you 24 hours a day, nonstop.</p>
						</div>
						<div class="img-holder wow fadeInRight">
							<div class="mockup-holder wow fadeIn">
								<img src="assets/images/mockup-holder.webp" alt="AAAFx" title="AAAFx" width="665" height="393" class="fluid-img" />
								<div class="video-holder">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section market-analysis">
			<div class="wrapper">
				<div class="title-holder">
					<h3>One stop solution for Market Analysis</h3>
				</div>

				<div class="slider-holder clearfix wow fadeIn">
					<ul class="slides">
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img1.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>Identification of Support and Resistance Levels</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img2.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>Real time Alerts to excessive movements</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img3.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>In depth analysis of Macro economic news</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img1.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>Identification of Support and Resistance Levels</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img2.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>Real time Alerts to excessive movements</p>
							</div>
						</li>
						<li>
							<div class="chart-holder">
								<img src="assets/images/market-img3.webp" alt="AAAFx" title="AAAFx" width="180" height="121" class="fluid-img" />
								<p>In depth analysis of Macro economic news</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="listing wow fadeIn">
					<div class="market-ico">
						<img src="assets/images/market-ico1.webp" alt="AAAFx" title="AAAFx" width="90" height="90" class="fluid-img" />
						<p>Single Screen access to everythinig</p>
					</div>
					<div class="market-ico">
						<img src="assets/images/market-ico2.webp" alt="AAAFx" title="AAAFx" width="90" height="90" class="fluid-img" />
						<p>Get simply plugged in to MT4 and MT5</p>
					</div>
					<div class="market-ico">
						<img src="assets/images/market-ico3.webp" alt="AAAFx" title="AAAFx" width="90" height="90" class="fluid-img" />
						<p>API Driven Technology, free from manual errors</p>
					</div>
					<div class="market-ico">
						<img src="assets/images/market-ico4.webp" alt="AAAFx" title="AAAFx" width="90" height="90" class="fluid-img" />
						<p>All-rounder for all types of Traders </p>
					</div>
				</div>

				<div class="autochartist-holder">
					<div class="img-holder">
						<img src="assets/images/chartist-img.webp" alt="AAAFx" title="AAAFx" width="475" height="209" class="fluid-img" />
					</div>
					<h3>Autochartist Volatility Analysis</h3>
					<p>Build your trading strategy based on facts.
						<br><br>
						Know the best time to trade an instrument by gaining knowledge of its most and the least volatile times during the day.
						<br><br>
						With this tool, you get to see the following:- <br><br>
						</p>
						<ul class="listing-check">
							<li>Expected price ranges in various timeframes </li>
							<li>Expected price movement by hour of the day</li>
							<li>Expected price movement by day of the week </li>
						</ul>
						<p>Know more here: <br> <a href="#">https://vimeo.com/310889082</a> </p>
				</div>
				<div class="autochartist-holder">
					<div class="img-holder">
						<img src="assets/images/chartist-img2.webp" alt="AAAFx" title="AAAFx" width="475" height="209" class="fluid-img" />
					</div>
					<h3>Autochartist Risk Calculator</h3>
					<p>Measure your Risk before trading.
					<br><br>
					Now you can be always aware of the ideal trading volume for you, keeping in mind your risk appetite.
					<br><br>
					This tool shows you the expected price movement ranges</p>
					<ul class="listing-check">
						<li>Set the desired entry level and stop loss level</li>
						<li>Enter the amount of money you are willing to risk on the trade</li>
						<li>The Calculator will tell you the position size to take</li>
					</ul>
					<p>Know more here: <br> <a href="#">https://vimeo.com/310889057</a> </p>
				</div>
				<div class="btn-holder t-center">
					<a href="#" class="btn btn-yellow">OPEN LIVE ACCOUNT</a>
					<a href="#" class="btn btn-blue">OPEN DEMO ACCOUNT</a>
				</div>
			</div>
		</div>
	</div>
</template>